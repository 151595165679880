<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <h1 class="mb-0 mt-0">Sign Up</h1>
    <p>Enter your email address and password to access  super admin panel. </p>
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider vid="email" name="E-mail" rules="required|email" v-slot="{ errors }">
        <div class="form-group">
          <label for="emailInput">Email address</label>
          <input type="email" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="emailInput" aria-describedby="emailHelp"
                 v-model="user.email" placeholder="Enter email" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider vid="password" name="Password" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="passwordInput">Password</label>
          <router-link to="/auth/password-reset1" class="float-right">
            Forgot password?
          </router-link>
          <input type="password"  :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="passwordInput"
                 v-model="user.password" placeholder="Password" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="d-inline-block w-100">
        <button type="submit" class="btn btn-primary float-right px-5 py-2">Sign in</button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
// import firebase from 'firebase'
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import { db, firebaseApp } from '@/config/firebase'

export default {
  name: 'SignInSuperAdmin',
  data: () => ({
    user: {
      switch: false,
      email: '',
      password: '',
      prueba: null
    }
  }),
  mounted () {
    core.index()
  },
  computed: {
    ...mapGetters({
      stateUsers: 'Setting/usersState'
    })
  },
  methods: {
    onSubmit () {
      this.firebaseLogin()
    },
    firebaseLogin () {
      firebaseApp.auth().signInWithEmailAndPassword(this.user.email, this.user.password)
        .then((user) => {
          const firebaseUser = firebaseApp.auth().currentUser.providerData[0]
          this.$store.dispatch('Setting/authUserAction', {
            auth: true,
            authType: 'firebase',
            user: {
              id: firebaseUser.uid,
              name: firebaseUser.displayName,
              mobileNo: firebaseUser.phones,
              email: firebaseUser.email,
              profileImage: firebaseUser.photoURL
            }
          })
          localStorage.setItem('user', JSON.stringify(firebaseUser))
          localStorage.setItem('uid', user.user.uid)
          db.collection('super-admin')
            .where('uid', '==', user.user.uid)
            .get()
            .then((querySnapshot) => {
              if (querySnapshot.empty) {
                localStorage.removeItem('user')
                localStorage.removeItem('access_token')
                firebaseApp.auth().signOut().then(() => {
                  core.showSnackbar('error', 'No está registrado como Admin')
                }).catch((error) => {
                  console.log(error)
                })
              } else {
                querySnapshot.forEach((doc) => {
                  localStorage.setItem('userDocId', doc.id)
                  localStorage.setItem('userInformation', JSON.stringify(doc.data()))
                })
                this.$router.push({ name: 'superadmin.dashboard' })
              }
            }).catch((error) => {
              console.log('Error getting documents: ', error)
            })
        })
        .catch((err) => {
          if (err.code === 'auth/user-not-found') {
            core.showSnackbar('error', 'These credentials do not match our records.')
          } else {
            core.showSnackbar('error', err.message)
          }
        })
    }
  }
}
</script>
